import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Layout, Parallax, DelayedLink, Observer } from '@common'
import {
	home__wrapper,
	home__parallax,
	home__parallax__inner,
	home__testimonial,
	home__title,
} from './styles/home.module.scss'

import {
	page__buttons,
	page__button,
	page__button__alt,
} from './styles/page.module.scss'

const Home = ({ pageContext, location }) => {
	const { WP } = useStaticQuery(graphql`
		query {
			WP {
				mediaItemBy(slug: "russell-crowe-gladiator") {
					sourceUrl
					srcSet
					altText
				}
			}
		}
	`)

	return (
		<Layout location={location} page={pageContext.page}>
			<div className={home__wrapper}>
				<h1>
					Całymi dniami <strong>pomagam</strong> niedoświadczonym
					programistom <strong>zdobywać nowe poziomy</strong> w
					skillach koderskich
				</h1>
				<h1>
					<strong>Systematycznie tworzę</strong> zbiory ambitnych
					zadań z HTML, CSS, JS, React i testów
				</h1>
				<h1>
					I <strong>dla dobra społeczności IT</strong> rozpocząłem i
					rozwijam inicjatywę darmowego dzielenia się wiedzą o nazwie
				</h1>
			</div>
			<div className={home__wrapper}>
				<div className={home__parallax}>
					<Parallax height={300} image={WP.mediaItemBy}>
						<div className={home__parallax__inner}>
							<Observer>
								<h1 className={home__title}>
									<strong>Gladiatorzy</strong> Javascriptu
								</h1>
							</Observer>
						</div>
					</Parallax>
				</div>
				<Observer>
					<div className={page__buttons}>
						<div className={`${page__button}`}>
							<span>&gt;</span>
							<DelayedLink to={'/why-worth/'}>
								O inicjatywie
							</DelayedLink>
						</div>
						<div className={`${page__button}`}>
							<span>&gt;</span>
							<DelayedLink to={'/about/'}>
								w punktach o mnie
							</DelayedLink>
						</div>
					</div>
				</Observer>
			</div>
			<div className={home__wrapper}>
				<h1>Do kogo jest kierowana ta inicatywa?</h1>
				<ul>
					<Observer>
						<li>
							<span>1</span>
							<h4>
								Jesteś <strong>
									po kursie lub bootcampie
								</strong>{' '}
								Udemy, Samuraja, Youtubowych lub innych?
							</h4>
						</li>
					</Observer>
					<Observer>
						<li>
							<span>2</span>
							<h4>
								<strong>Szukasz praktyki</strong>, ciekawych
								zadań oraz uporządkowanych materiałów?
							</h4>
						</li>
					</Observer>

					<Observer>
						<li>
							<span>3</span>
							<h4>
								Myślisz, że jesteś{' '}
								<strong>gotowy/-a na rekrutację</strong> na
								juniora ? <strong>:D</strong>
							</h4>
						</li>
					</Observer>
				</ul>
			</div>

			<div className={home__wrapper}>
			<Observer>
				<h1>
					Poczytaj co myślą osoby, które skorzystały na mojej
					inicjatywie oraz zdobyły nową wiedzę i umiejętności
				</h1>
				</Observer>
				<ul className={home__testimonial}>
						<li>
							<h6>Mateusz</h6>
							<h4>
								Ciekawe zadania, dzięki którym można utrwalić
								wiedzę, a przy tym poznać coś nowego ze świata
								JSa i Reacta
							</h4>
						</li>
						<li>
							<h6>Krzysztof</h6>
							<h4>
								Fajna inicjatywa 💪🏻 i ogromna ilość zadań. No
								to biorę się za kodowanie :){' '}
							</h4>
						</li>
						<li>
							<h6>Marcin</h6>
							<h4>
								Jedno z najlepszy miejsc gdzie począktujący
								programista ma szanse sprawdzić swoje realne
								umiejętności i wiedzę w praktyce
							</h4>
						</li>
						<li>
							<h6>Monika</h6>
							<h4>
								Ćwiczę z Przemkiem już jakiś czas zadania i
								czuję, że dopiero teraz zaczynam uczyć się
								dobrze kodować
							</h4>
						</li>
						<li>
							<h6>Paweł</h6>
							<h4>
								Gorąco polecam! Nauka pod okiem wymagającego,
								ale i bardzo pomocnego profesjonalnego mentora.
								Szczegółowy code review i porządny feedback.
								Nacisk na dobre praktyki kodowania. Jestem
								totalnie wkręcony w naukę. Pozdrawiam!
							</h4>
						</li>
						<li>
							<h6>Daniel</h6>
							<h4>
								Polecam tego allegrowicza! Wymagające zadania
								sprawdzą Twoją wiedzę i pozwolą doszlifować
								umiejętności
							</h4>
						</li>
						<li>
							<h6>Rafał</h6>
							<h4>
								Ponad 3 lata jestem Javascript Devem, a do
								niektórych zadań, żeby podejść to musiałbym
								porządnie się zastanowić jak je zrobić. Świetny
								pomysł i wykonanie!
							</h4>
						</li>
						<li>
							<h6>Dorota</h6>
							<h4>
								Ale trudne te zadania, uświadomiłam sobie jak
								niewiele umiem i jak dużo pracy jeszcze mnie
								czeka
							</h4>
						</li>
						<li>
							<h6>Michał</h6>
							<h4>
								Super inicjatywa. I ceny widzę też bardzo
								przystępne. Zadania ciekawe, bardzo fajne
								oderwanie od codziennych zajec na projekcie
							</h4>
						</li>
				</ul>
				<Observer>
					<div className={page__buttons}>
						<div className={`${page__button}`}>
							<span>&gt;</span>
							<DelayedLink to={'/why-worth/'}>
								Przeczytaj i oceń sam
							</DelayedLink>
						</div>
					</div>
				</Observer>
			</div>
		</Layout>
	)
}

export default Home
